import React from 'react'
import { useRef } from "react"

/**
 * orice
 * @param {Array} chatHistory - istoricul chatului
 * @param {Function} setChatHistory 
 * @param {Function} generateBotResponse 
 * @returns { HTML }
 */
const ChatForm = ({ chatHistory, setChatHistory, generateBotResponse }) => {

  const inputRef = useRef();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const userMessage = inputRef.current.value.trim();

    if (!userMessage)
      return;
    inputRef.current.value = "";

    //update pt chat history cu user si mesaj
    setChatHistory((history) => [...history, { role: "user", text: userMessage }]);


    setTimeout(() => {
      setChatHistory((history) => [...history, { role: "model", text: "Hmm..." }]);

      generateBotResponse([...chatHistory, { role: "user", text: `Using the details provided above please adress this query: ${userMessage }` }]);
    }, 600);
  }

  const handleInputChange = (e) => {
    const button = document.getElementById("materialSymbolsRounded");
    if(e.target.value != '') {
      button.classList.add("changeButtonColor");
    } else {
      button.classList.remove("changeButtonColor");
    }
  }
  
  return (
    <form action="#" className="chat-form" onSubmit={handleFormSubmit}>
      <input ref={inputRef} onChange={handleInputChange} type="text" placeholder="Scrie..." className="message-input" required />
      <button id="materialSymbolsRounded" className="material-symbols-rounded">arrow_upward</button>
    </form>
  )
}


export default ChatForm