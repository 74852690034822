import React, { useState, useEffect, useRef } from "react";
import { Route, Routes } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import ScrollToTop from "./components/ScrollToTop";
import "process/browser"; // This imports the polyfill for process
import ChatBotIcon from "./components/ChatBotIcon";
import ChatForm from "./components/ChatForm";
import ChatMessage from "./components/ChatMessage";
import { companyInfo } from "./companyInfo";

// Styles
import "./styles/general.css";
import "./styles/buttons.scss";
import "./styles/shinyCTA.scss";

// Components
import Header from "./components/Header";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";

// Data
import { navigationData } from "./data/navigationData";

function App() {
    // Get the initial theme index from localStorage, if it exists, otherwise default to 0
    const initialThemeIndex = parseInt(localStorage.getItem("themeIndex")) || 0;
    const [themeIndex, setThemeIndex] = useState(initialThemeIndex);
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        applyTheme(themeIndex);
    }, [themeIndex]);

    const colorThemes = [
        {
            "--background-color": "#000000",
            "--color-icons": "#FDEDDD",
            "--color-component-background": "#0f0f0f",
            "--color-element-background": "#1a1a1a",
            "--particles-color": "#FFFFFF",
            "--color-text": "#FDEDDD",
        },
        {
            "--background-color": "#F4F4F4",
            "--color-icons": "#9B9595",
            "--color-component-background": "#FFFFFF",
            "--color-element-background": "#F4F4F4",
            "--particles-color": "#000000",
            "--color-text": "#000000",
        },
    ];

    const changeColors = () => {
        const newIndex = (themeIndex + 1) % colorThemes.length;
        setThemeIndex(newIndex);
        localStorage.setItem("themeIndex", newIndex); // Save the new index in localStorage
    };

    const applyTheme = (index) => {
        const newTheme = colorThemes[index];

        // Check if the new theme is already applied
        const rootStyle = getComputedStyle(document.documentElement);
        const isThemeApplied = Object.keys(newTheme).every(
            (key) => rootStyle.getPropertyValue(key).trim() === newTheme[key]
        );

        if (isThemeApplied) return; // Skip applying the theme if it is already applied

        // Apply each color variable to the document root
        Object.keys(newTheme).forEach((key) => {
            document.documentElement.style.setProperty(key, newTheme[key]);
        });

        setIsAnimating(true);
        window.dispatchEvent(new Event("themeChange"));
        setTimeout(() => {
            setIsAnimating(false);
        }, 2000); // Match the transition duration
    };

    // Pentru chatAi
    const [chatHistory, setChatHistory] = useState([
        {
            hideInChat: true,
            role: "model",
            text: companyInfo,
        }]);
    const [showChatbot, setShowChatbot] = useState(false);
    const chatBodyRef = useRef();

    const generateBotResponse = async (history) => {

        const updateHistory = (text) => {
            setChatHistory(prev => [...prev.filter(msg => msg.text !== "Hmm..."), { role: "model", text }]);
        };
        
        const apiUrl = "https://www.easyinfotech.ro:5000/chat";
    
        // Corectăm formatul istoricului conversației
        const messages = history.map(({ role, text }) => ({
            role: role === "model" ? "assistant" : "user",
            content: text
        }));
    
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                messages: messages
            })
        };
    
        try {
            const response = await fetch(apiUrl, requestOptions);
            const data = await response.json();
    
            if (!response.ok) {
                console.error("🚨 OpenAI API Error:", data);
                throw new Error(data.error?.message || "Something went wrong!");
            }
    
            const apiResponseText = data.choices[0].message.content.trim();
            updateHistory(apiResponseText);
        } catch (error) {
            console.error("🚨 Fetch Error:", error);
            updateHistory("Chatbot-ul este momentan indisponibil. Te rugăm să ne contactezi!");
        }
    };
    

    useEffect(() => {
        //Optiunea de autoscroll de fiecare data cand se incarca un nou mesaj
        chatBodyRef.current.scrollTo({ top: chatBodyRef.current.scrollHeight, behavior: "smooth" });
    }, [chatHistory])
    /////////

    return (
        <>
            <div className={`container ${showChatbot ? "show-chatbot" : ""}`}>
                <button onClick={() => setShowChatbot(prev => !prev)} id="chatbot-toggler">
                    <span className="material-symbols-rounded">mode_comment</span>
                    <span className="material-symbols-rounded">close</span>
                </button>

                <div className="chatbot-popup">

                    {/* ChatBot Header */}

                    <div className="chat-header">
                        <div className="header-info">
                            <ChatBotIcon />
                            <h2 className="logo-text">EasyInfoTech Chatbot</h2>
                        </div>
                        <button onClick={() => setShowChatbot(prev => !prev)} className="material-symbols-rounded">
                            keyboard_arrow_down
                        </button>
                    </div>

                    {/* ChatBot Body */}

                    <div ref={chatBodyRef} className="chat-body">
                        <div className="message bot-message">
                            <ChatBotIcon />
                            <p className="message-text">
                                Salut! 👋<br /> Cu ce te pot ajuta?
                            </p>
                        </div>


                        {chatHistory?.map((chat, index) => (
                            <ChatMessage key={index} chat={chat} />
                        ))}
                    </div>

                    {/* ChatBot Footer */}

                    <div className="chat-footer">
                        <ChatForm chatHistory={chatHistory} setChatHistory={setChatHistory} generateBotResponse={generateBotResponse} />
                    </div>
                </div>
            </div>

            <ScrollToTop />
            <CookieConsent
                location="bottom"
                cookieName="cookieAcceptance"
                expires={30}
                overlay
            >
                This website uses essential cookies and local storage to enhance
                the user experience.
            </CookieConsent>
            <main id="page">
                <div className="contentContainer">
                    {/* Particles needed for background particles needed in AudioVisualiser.js */}
                    <div className="particles" id="particles-slow"></div>
                    <div className="particles" id="particles-fast"></div>
                    <Header
                        changeColors={changeColors}
                        isAnimating={isAnimating}
                    />
                    <Routes>
                        {navigationData.links.map((menuItem, index) => {
                            return (
                                <Route
                                    key={index}
                                    path={menuItem.href}
                                    element={<menuItem.component />}
                                />
                            );
                        })}
                    </Routes>
                    <Footer />
                    <Navbar data={navigationData.navbarData} />
                </div>
            </main>
        </>
    );
}

export default App;
