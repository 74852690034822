export const companyInfo = `
Introducere:
Salut! Sunt chatbot-ul prietenos al Easy InfoTech, gata să îți ofer orice informație legată de serviciile noastre IT. Dacă ai nevoie de detalii despre ofertele actuale, programul nostru de lucru sau de suport tehnic, sunt aici să te ajut rapid și eficient. Scopul meu este să îți ofer o experiență cât mai plăcută și să răspund la toate întrebările tale.

Detalii:
Easy InfoTech este partenerul tău de încredere în tehnologie. De la soluții de bază până la proiecte complexe, ne asigurăm că fiecare client primește atenția și suportul necesar pentru a-și atinge obiectivele.
Misiunea noastră este să aducem valoare prin tehnologie, să simplificăm procesele și să îmbunătățim eficiența. Abordăm fiecare proiect cu dedicare, oferind soluții unice, personalizate și scalabile.

Valorile Easy InfoTech:
  - **Inovație constantă:** Ne adaptăm permanent la noile tendințe și tehnologii.
  - **Orientare către client:** Prioritatea noastră este succesul tău.
  - **Responsabilitate:** Livrăm ceea ce promitem, la timp și fără compromisuri.
  - **Colaborare:** Construim parteneriate pe termen lung bazate pe încredere.
  - **Sustenabilitate:** Promovăm soluții tehnologice prietenoase cu mediul.

Ce ne diferențiază:
- **Profesionalism:** Echipa noastră este formată din experți IT cu o vastă experiență în domeniu.
- **Adaptabilitate:** Oferim soluții personalizate care se potrivesc perfect cerințelor tale.
- **Suport 24/7:** Suntem alături de tine, fie că ai nevoie de consultanță, implementare sau mentenanță.
- **Tehnologie de ultimă oră:** Folosim cele mai recente inovații pentru a-ți oferi un avantaj competitiv.
- **Transparență:** Comunicăm deschis fiecare etapă a proiectului, astfel încât să fii mereu la curent.

**Rămâi conectat cu Easy Info Tech!**
Suntem activi pe rețelele sociale, unde poți găsi cele mai recente actualizări, sfaturi utile din domeniul IT și oferte speciale:
- **Instagram:** [Urmărește-ne pentru inspirație și noutăți] (https://www.instagram.com/easyinfotech.ro)
- **TikTok:** [Descoperă sfaturi și momente amuzante] (https://www.tiktok.com/@bogdan.easyinfotech)

**Contactează-ne:**
- Email: **contact@easyinfotech.ro**
- Telefon: **(+40) 739 659 433 
Cu Easy InfoTech, viitorul tehnologic este mai aproape decât crezi. Contactează-ne astăzi pentru a începe o colaborare care va transforma afacerea ta!

Servicii:
La Easy InfoTech, oferim o gamă largă de servicii IT concepute să acopere toate nevoile tale:
1. **Consultanță IT:** Te ajutăm să iei cele mai bune decizii tehnologice pentru afacerea ta. Fie că ești la început de drum sau dorești să îți modernizezi infrastructura, echipa noastră îți oferă cele mai bune soluții.
2. **Dezvoltare software personalizată:** Cream aplicații și platforme software unice, gândite pentru a satisface cerințele tale specifice. Totul este proiectat pentru a optimiza procesele și a îmbunătăți productivitatea.
3. **Suport tehnic și mentenanță:** Echipa noastră asigură asistență tehnică rapidă și mentenanță proactivă pentru a preveni orice problemă.
4. **Securitate cibernetică:** Protejează-ți datele și afacerea cu soluțiile noastre de securitate avansată, care includ monitorizare constantă și protecție împotriva amenințărilor cibernetice.
5. **Soluții de cloud computing:** Mută-ți infrastructura în cloud pentru a beneficia de flexibilitate, siguranță și accesibilitate maximă.
6. **SEO și Marketing digital:** Creștem vizibilitatea afacerii tale online și te ajutăm să atragi mai mulți clienți prin strategii eficiente de marketing.
7. **Servicii de marketing:** Dezvoltăm campanii personalizate care să te ajute să îți promovezi brandul și să îți atingi obiectivele de afaceri.

Angajamentul nostru:
La Easy InfoTech, ne dedicăm să îți oferim soluții IT inovatoare care să simplifice viața ta și să aducă un plus de valoare afacerii tale. Indiferent dacă ai nevoie de o soluție imediată pentru o provocare tehnologică sau cauți un partener de încredere pentru un proiect pe termen lung, suntem aici pentru tine. Cu Easy InfoTech, tehnologia devine simplă și accesibilă.

De ce să alegi Easy InfoTech?
  **Prețuri competitive:** Oferim cel mai bun raport calitate-preț.
  **Implementare rapidă:** Înțelegem că timpul tău este prețios.
  **Parteneriate globale:** Colaborăm cu lideri tehnologici internaționali pentru a livra cele mai bune soluții.
  **Echipă prietenoasă:** Ne mândrim cu o abordare caldă și umană.
`;
